import moment from 'moment';
import Card from '../../../shared/Card';
import {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {parsePhoneNumberFromString} from 'libphonenumber-js';

// const libphonenumber = require('google-libphonenumber');

const Details = observer(({data}) => {
  // const formaterNumber = (phone) => {
  //   const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  //   const phoneNumber = phoneUtil.parse(phone);
  //   const formattedNumber = phoneUtil.format(
  //     phoneNumber,
  //     libphonenumber.PhoneNumberFormat.INTERNATIONAL
  //   );

  //   console.log(formattedNumber);
  // };

  const store = useStore();

  const [countryFlag, setCountryFlag] = useState();

  useEffect(() => {
    if (data) {
      getData();
    }
  }, [data]);

  const getData = async () => {
    const getCountries = await store.getCountries();
    const finCountry = getCountries.find((item) => item.name === data?.geo);

    setCountryFlag(finCountry.flag);
  };

  function calculateAge(birthdate) {
    if (!birthdate) return;

    const parts = birthdate.split('/');

    const birthDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const calculateDaysWork = (dateStart) => {
    if (!dateStart) return;

    const parts = dateStart.split('/');
    const startDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
    const currentDate = new Date();
    const difference = currentDate.getTime() - startDate.getTime();

    return Math.floor(difference / (1000 * 60 * 60 * 24)).toString();
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return;
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedPhoneNumber) {
      return parsedPhoneNumber.formatInternational();
    }
    return phoneNumber;
  };

  return (
    <>
      <Card className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Данные сотрудника</h3>
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-4 fw-semibold text-muted'>{'Имя'}</label>

            <div className='col-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>
                {data?.first_name} {data?.last_name}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-4 fw-semibold text-muted'>Дата Рождения</label>

            <div className='col-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>
                {data?.date_birth.split('-').reverse().join('/')}
              </span>
              <span className='badge badge-light'>
                {calculateAge(data?.date_birth.split('-').reverse().join('/'))}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-4 fw-semibold text-muted'>Начал работать</label>

            <div className='col-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>
                {moment(data?.created_at * 1000).format('DD/MM/YYYY')}
              </span>
              <span className='badge badge-light'>
                {calculateDaysWork(moment(data?.created_at * 1000).format('DD/MM/YYYY'))}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-4 fw-semibold text-muted'>
              Номер телефона
              <span className='ms-1' data-bs-toggle='tooltip' title={'Текущий номер телефона'}>
                <i className='ki-duotone ki-information fs-7'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              </span>
            </label>

            <div className='col-8 d-flex align-items-center'>
              {/*{formaterNumber(data?.phone)} */}
              <span className='fw-bold fs-6 text-gray-800 me-2'>
                {formatPhoneNumber(`+${data?.phone}`)}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-4 fw-semibold text-muted'>Почта</label>

            <div className='col-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.email}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-4 fw-semibold text-muted'>
              Страна проживания
              {/* <span className='ms-1' data-bs-toggle='tooltip' title={'Страна нахождения'}>
                <i className='ki-duotone ki-information fs-7'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              </span> */}
            </label>

            <div className='col-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.geo}</span>
              <span className='symbol symbol-circle symbol-20px me-4'>
                <img className='' src={countryFlag} alt='metronic' />
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-4 fw-semibold text-muted'>Телеграм ID</label>

            <div className='col-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.telegram_id}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-4 fw-semibold text-muted'>Телеграм UserName</label>

            <div className='col-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>@{data?.telegram_username}</span>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
});

export default Details;
