/* eslint-disable no-sparse-arrays */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import css from '../../../../../../../styles/Treker.module.scss';
import classNames from 'classnames';
import {useThemeMode} from '../../../../../../../_metronic/partials';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../../store/store';
import {
  formatNumber,
  formatNumber2,
  formatNumberToCash,
} from '../../../../../../functions/formatNumber';
import Preloader from '../../../../../../modules/Preloader';
import Tabs from './Tabs';
import Actions from './Actions';
import Table from './Table';

// const trafficsFacebookLinksTrackerGetCompanies = async (data) => {
//   let queryStr = '';

//   for (let [key, value] of Object.entries(data)) {
//     if (key === 'token') {
//       queryStr += `?${key}=${value}`;
//       continue;
//     }
//     if (key === 'timeConfig') {
//       if (!value) continue;
//       queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
//       continue;
//     }

//     queryStr += `&${key}=${value}`;
//   }
//   try {
//     const response = await fetch(
//       `https://api.traffhunt.com/api/Traffics/Facebook/Links/Tracker/getCompanies${queryStr}`
//     );
//     return response.json();
//   } catch (error) {
//     console.error('Основной сервер не отвечает', error);
//     return {success: error.message};
//   }
// };
// const trafficsFacebookLinksTrackerGetAdsets = async (data) => {
//   let queryStr = '';

//   for (let [key, value] of Object.entries(data)) {
//     if (key === 'token') {
//       queryStr += `?${key}=${value}`;
//       continue;
//     }
//     if (key === 'timeConfig') {
//       if (!value) continue;
//       queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
//       continue;
//     }

//     queryStr += `&${key}=${value}`;
//   }
//   try {
//     const response = await fetch(
//       `https://api.traffhunt.com/api/Traffics/Facebook/Links/Tracker/getAdsets${queryStr}`
//     );
//     return response.json();
//   } catch (error) {
//     console.error('Основной сервер не отвечает', error);
//     return {success: error.message};
//   }
// };
// const trafficsFacebookLinksTrackerGetAds = async (data) => {
//   let queryStr = '';

//   for (let [key, value] of Object.entries(data)) {
//     if (key === 'token') {
//       queryStr += `?${key}=${value}`;
//       continue;
//     }
//     if (key === 'timeConfig') {
//       if (!value) continue;
//       queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
//       continue;
//     }

//     queryStr += `&${key}=${value}`;
//   }
//   try {
//     const response = await fetch(
//       `https://api.traffhunt.com/api/Traffics/Facebook/Links/Tracker/getAds${queryStr}`
//     );
//     return response.json();
//   } catch (error) {
//     console.error('Основной сервер не отвечает', error);
//     return {success: error.message};
//   }
// };

const Treker = observer(({timeConfig, id}) => {
  const store = useStore();
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');

  const [preloader, setPreloader] = useState(true);

  const [titlesCompanies, setTitlesCompanies] = useState([]);
  const [titlesAdsets, setTitlesAdsets] = useState([]);
  const [titlesAds, setTitlesAds] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [companiesQ, setCompaniesQ] = useState([]);

  const [adsets, setAdsets] = useState([]);
  const [adsetsQ, setAdsetsQ] = useState([]);

  const [ads, setAds] = useState([]);
  const [adsQ, setAdsQ] = useState([]);

  const [companiesSearch, setCompaniesSearch] = useState([]);
  const [adsetsSearch, setAdsetsSearch] = useState([]);
  const [adsSearch, setAdsSearch] = useState([]);

  const [checkedCompanies, setCheckedCompanies] = useState([]);
  const [checkedAdsets, setCheckedAdsets] = useState([]);
  const [checkedAds, setCheckedAds] = useState([]);

  const [currentShowTable, setCurrentShowTable] = useState(1);

  const [sortCompanies, setSortCompanies] = useState(null);
  const [sortAdsets, setSortAdsets] = useState(null);
  const [sortAds, setSortAds] = useState(null);

  const [preSortCompanies, setPreSortCompanies] = useState(null);
  const [preSortAdsets, setPreSortAdsets] = useState(null);
  const [preSortAds, setPreSortAds] = useState(null);

  const [exportCompanies, setExportCompanies] = useState({});
  const [exportAdsets, setExportAdsets] = useState({});
  const [exportAds, setExportAds] = useState({});
  const [date, setDate] = useState({});

  useEffect(() => {
    timeConfig.time_config && getData();
  }, [timeConfig]);

  const getData = async (params = {}) => {
    setPreloader(true);
    const [res1, res2, res3] = await Promise.all([
      store.trafficsFacebookLinksTrackerGetCompanies({
        token: '3e5bc16c2311e36978fae93da31979e1b5a4869d',
        timeConfig,
        id,
      }),
      store.trafficsFacebookLinksTrackerGetAdsets({
        token: '3e5bc16c2311e36978fae93da31979e1b5a4869d',
        timeConfig,
        id,
      }),
      store.trafficsFacebookLinksTrackerGetAds({
        token: '3e5bc16c2311e36978fae93da31979e1b5a4869d',
        timeConfig,
        id,
      }),
    ]);

    getCompanies(res1);
    getAdsets(res2);
    getAds(res3);

    setSortCompanies(null);
    setSortAdsets(null);
    setSortAds(null);

    setPreSortCompanies(null);
    setPreSortAdsets(null);
    setPreSortAds(null);

    setPreloader(false);
  };

  const getCompanies = async (result) => {
    setDate(result.time);
    const templateObj = {
      Campaign: null,
      'Campaign ID': null,
      Клики: null,
      Лиды: null,
      Чаты: null,
      Регистрации: null,
      'Первые депозиты': null,
      Квалификации: null,
      Доход: null,
      Click2Lead: null,
      Click2Chat: null,
      Click2Registration: null,
      Click2FirstDeposit: null,
      Click2Cpa: null,
      Lead2Chat: null,
      Lead2Registration: null,
      Lead2FirstDeposit: null,
      Lead2Cpa: null,
      Registration2FirstDeposit: null,
      Registration2Cpa: null,
      FirstDeposit2Cpa: null,
    };

    setTitlesCompanies(Object.keys(templateObj));

    const config = localStorage.getItem('appTrekerConfig');
    const configResult = JSON.parse(config) || null;

    let q = [];
    if (!!configResult && !!configResult.companies && !!configResult?.companies?.length) {
      q = configResult.companies;
    } else {
      for (let i = 0; i < Object.keys(templateObj).length; i++) {
        q.push(i);
      }
    }

    setCompaniesQ(q);

    const totals = {
      Клики: 0,
      Лиды: 0,
      Чаты: 0,
      Регистрации: 0,
      'Первые депозиты': 0,
      Квалификации: 0,
      Доход: 0,
      Click2Lead: 0,
      Click2Chat: 0,
      Click2Registration: 0,
      Click2FirstDeposit: 0,
      Click2Cpa: 0,
      Lead2Chat: 0,
      Lead2Registration: 0,
      Lead2FirstDeposit: 0,
      Lead2Cpa: 0,
      Registration2FirstDeposit: 0,
      Registration2Cpa: 0,
      FirstDeposit2Cpa: 0,
    };

    let count = result.data.length;

    const sortedArray = result.data.map((item) => {
      const obj = {...templateObj};

      obj['Campaign'] = !!item.campaign_name ? item.campaign_name : '-';
      obj['Campaign ID'] = !!item.campaign_id ? item.campaign_id : '-';
      obj['Клики'] = !!item.count_clicks ? formatNumber(item.count_clicks) : '-';
      obj['Лиды'] = !!item.count_leads ? formatNumber(item.count_leads) : '-';
      obj['Чаты'] = !!item.count_appeals ? formatNumber(item.count_appeals) : '-';
      obj['Регистрации'] = !!item.count_registrations
        ? formatNumber(item.count_registrations)
        : '-';
      obj['Первые депозиты'] = !!item.count_deposits ? formatNumber(item.count_deposits) : '-';
      obj['Квалификации'] = !!item.count_qualifications
        ? formatNumber(item.count_qualifications)
        : '-';
      obj['Доход'] = !!item.revenue ? formatNumberToCash(item.revenue) : '-';

      // Обчислення відсотків
      obj['Click2Lead'] =
        item.count_clicks && item.count_leads
          ? `${formatNumber2((item.count_leads / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2Chat'] =
        item.count_clicks && item.count_appeals
          ? `${formatNumber2((item.count_appeals / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2Registration'] =
        item.count_clicks && item.count_registrations
          ? `${formatNumber2((item.count_registrations / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2FirstDeposit'] =
        item.count_clicks && item.count_deposits
          ? `${formatNumber2((item.count_deposits / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2Cpa'] =
        item.count_clicks && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_clicks) * 100)}%`
          : '-';
      obj['Lead2Chat'] =
        item.count_leads && item.count_appeals
          ? `${formatNumber2((item.count_appeals / item.count_leads) * 100)}%`
          : '-';
      obj['Lead2Registration'] =
        item.count_leads && item.count_registrations
          ? `${formatNumber2((item.count_registrations / item.count_leads) * 100)}%`
          : '-';
      obj['Lead2FirstDeposit'] =
        item.count_leads && item.count_deposits
          ? `${formatNumber2((item.count_deposits / item.count_leads) * 100)}%`
          : '-';
      obj['Lead2Cpa'] =
        item.count_leads && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_leads) * 100)}%`
          : '-';
      obj['Registration2FirstDeposit'] =
        item.count_registrations && item.count_deposits
          ? `${formatNumber2((item.count_deposits / item.count_registrations) * 100)}%`
          : '-';
      obj['Registration2Cpa'] =
        item.count_registrations && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_registrations) * 100)}%`
          : '-';
      obj['FirstDeposit2Cpa'] =
        item.count_deposits && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_deposits) * 100)}%`
          : '-';

      return obj;
    });

    setCompanies(sortedArray);
    setCompaniesSearch(sortedArray);
  };
  const getAdsets = async (result) => {
    const templateObj = {
      'Ad set': null,
      'Ad set ID': null,
      Campaign: null,
      'Campaign ID': null,
      Клики: null,
      Лиды: null,
      Чаты: null,
      Регистрации: null,
      'Первые депозиты': null,
      Квалификации: null,
      Доход: null,
      Click2Lead: null,
      Click2Chat: null,
      Click2Registration: null,
      Click2FirstDeposit: null,
      Click2Cpa: null,
      Lead2Chat: null,
      Lead2Registration: null,
      Lead2FirstDeposit: null,
      Lead2Cpa: null,
      Registration2FirstDeposit: null,
      Registration2Cpa: null,
      FirstDeposit2Cpa: null,
    };
    setTitlesAdsets(Object.keys(templateObj));

    const config = localStorage.getItem('appTrekerConfig');
    const configResult = JSON.parse(config) || null;

    let q = [];
    if (!!configResult && !!configResult.adsets && !!configResult?.adsets?.length) {
      q = configResult.adsets;
    } else {
      for (let i = 0; i < Object.keys(templateObj).length; i++) {
        q.push(i);
      }
    }

    setAdsetsQ(q);

    const sortedArray = result.data.map((item) => {
      const obj = {...templateObj};
      obj['Ad set'] = !!item.adset_name ? item.adset_name : '-';
      obj['Ad set ID'] = !!item.adset_id ? item.adset_id : '-';
      obj['Campaign'] = !!item.campaign_name ? item.campaign_name : '-';
      obj['Campaign ID'] = !!item.campaign_id ? item.campaign_id : '-';
      obj['Клики'] = !!item.count_clicks ? formatNumber(item.count_clicks) : '-';
      obj['Лиды'] = !!item.count_leads ? formatNumber(item.count_leads) : '-';
      obj['Чаты'] = !!item.count_appeals ? formatNumber(item.count_appeals) : '-';
      obj['Регистрации'] = !!item.count_registrations
        ? formatNumber(item.count_registrations)
        : '-';
      obj['Первые депозиты'] = !!item.count_deposits ? formatNumber(item.count_deposits) : '-';
      obj['Квалификации'] = !!item.count_qualifications
        ? formatNumber(item.count_qualifications)
        : '-';
      obj['Доход'] = !!item.revenue ? formatNumberToCash(item.revenue) : '-';

      // Обчислення відсотків
      obj['Click2Lead'] =
        item.count_clicks && item.count_leads
          ? `${formatNumber2((item.count_leads / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2Chat'] =
        item.count_clicks && item.count_appeals
          ? `${formatNumber2((item.count_appeals / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2Registration'] =
        item.count_clicks && item.count_registrations
          ? `${formatNumber2((item.count_registrations / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2FirstDeposit'] =
        item.count_clicks && item.count_deposits
          ? `${formatNumber2((item.count_deposits / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2Cpa'] =
        item.count_clicks && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_clicks) * 100)}%`
          : '-';
      obj['Lead2Chat'] =
        item.count_leads && item.count_appeals
          ? `${formatNumber2((item.count_appeals / item.count_leads) * 100)}%`
          : '-';
      obj['Lead2Registration'] =
        item.count_leads && item.count_registrations
          ? `${formatNumber2((item.count_registrations / item.count_leads) * 100)}%`
          : '-';
      obj['Lead2FirstDeposit'] =
        item.count_leads && item.count_deposits
          ? `${formatNumber2((item.count_deposits / item.count_leads) * 100)}%`
          : '-';
      obj['Lead2Cpa'] =
        item.count_leads && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_leads) * 100)}%`
          : '-';
      obj['Registration2FirstDeposit'] =
        item.count_registrations && item.count_deposits
          ? `${formatNumber2((item.count_deposits / item.count_registrations) * 100)}%`
          : '-';
      obj['Registration2Cpa'] =
        item.count_registrations && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_registrations) * 100)}%`
          : '-';
      obj['FirstDeposit2Cpa'] =
        item.count_deposits && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_deposits) * 100)}%`
          : '-';

      return obj;
    });

    setAdsets(sortedArray);
    setAdsetsSearch(sortedArray);
  };
  const getAds = async (result) => {
    const templateObj = {
      Ad: null,
      'Ad ID': null,
      'Ad set': null,
      'Ad set ID': null,
      Campaign: null,
      'Campaign ID': null,
      Клики: null,
      Лиды: null,
      Чаты: null,
      Регистрации: null,
      'Первые депозиты': null,
      Квалификации: null,
      Доход: null,
      Click2Lead: null,
      Click2Chat: null,
      Click2Registration: null,
      Click2FirstDeposit: null,
      Click2Cpa: null,
      Lead2Chat: null,
      Lead2Registration: null,
      Lead2FirstDeposit: null,
      Lead2Cpa: null,
      Registration2FirstDeposit: null,
      Registration2Cpa: null,
      FirstDeposit2Cpa: null,
    };

    setTitlesAds(Object.keys(templateObj));

    const config = localStorage.getItem('appTrekerConfig');
    const configResult = JSON.parse(config) || null;

    let q = [];
    if (!!configResult && !!configResult.ads && !!configResult?.ads?.length) {
      q = configResult.ads;
    } else {
      for (let i = 0; i < Object.keys(templateObj).length; i++) {
        q.push(i);
      }
    }

    setAdsQ(q);

    const sortedArray = result.data.map((item) => {
      const obj = {...templateObj};
      obj['Ad'] = !!item.ad_name ? item.ad_name : '-';
      obj['Ad ID'] = !!item.ad_id ? item.ad_id : '-';
      obj['Ad set'] = !!item.adset_name ? item.adset_name : '-';
      obj['Ad set ID'] = !!item.adset_id ? item.adset_id : '-';
      obj['Campaign'] = !!item.campaign_name ? item.campaign_name : '-';
      obj['Campaign ID'] = !!item.campaign_id ? item.campaign_id : '-';
      obj['Клики'] = !!item.count_clicks ? formatNumber(item.count_clicks) : '-';
      obj['Лиды'] = !!item.count_leads ? formatNumber(item.count_leads) : '-';
      obj['Чаты'] = !!item.count_appeals ? formatNumber(item.count_appeals) : '-';
      obj['Регистрации'] = !!item.count_registrations
        ? formatNumber(item.count_registrations)
        : '-';
      obj['Первые депозиты'] = !!item.count_deposits ? formatNumber(item.count_deposits) : '-';
      obj['Квалификации'] = !!item.count_qualifications
        ? formatNumber(item.count_qualifications)
        : '-';
      obj['Доход'] = !!item.revenue ? formatNumberToCash(item.revenue) : '-';

      // Обчислення відсотків
      obj['Click2Lead'] =
        item.count_clicks && item.count_leads
          ? `${formatNumber2((item.count_leads / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2Chat'] =
        item.count_clicks && item.count_appeals
          ? `${formatNumber2((item.count_appeals / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2Registration'] =
        item.count_clicks && item.count_registrations
          ? `${formatNumber2((item.count_registrations / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2FirstDeposit'] =
        item.count_clicks && item.count_deposits
          ? `${formatNumber2((item.count_deposits / item.count_clicks) * 100)}%`
          : '-';
      obj['Click2Cpa'] =
        item.count_clicks && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_clicks) * 100)}%`
          : '-';
      obj['Lead2Chat'] =
        item.count_leads && item.count_appeals
          ? `${formatNumber2((item.count_appeals / item.count_leads) * 100)}%`
          : '-';
      obj['Lead2Registration'] =
        item.count_leads && item.count_registrations
          ? `${formatNumber2((item.count_registrations / item.count_leads) * 100)}%`
          : '-';
      obj['Lead2FirstDeposit'] =
        item.count_leads && item.count_deposits
          ? `${formatNumber2((item.count_deposits / item.count_leads) * 100)}%`
          : '-';
      obj['Lead2Cpa'] =
        item.count_leads && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_leads) * 100)}%`
          : '-';
      obj['Registration2FirstDeposit'] =
        item.count_registrations && item.count_deposits
          ? `${formatNumber2((item.count_deposits / item.count_registrations) * 100)}%`
          : '-';
      obj['Registration2Cpa'] =
        item.count_registrations && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_registrations) * 100)}%`
          : '-';
      obj['FirstDeposit2Cpa'] =
        item.count_deposits && item.count_qualifications
          ? `${formatNumber2((item.count_qualifications / item.count_deposits) * 100)}%`
          : '-';

      return obj;
    });

    setAds(sortedArray);
    setAdsSearch(sortedArray);
  };

  const cleanCheckedElements = (id) => {
    setSortCompanies(null);
    setSortAdsets(null);
    setSortAds(null);

    setPreSortCompanies(null);
    setPreSortAdsets(null);
    setPreSortAds(null);

    if (id === 1) {
      if (checkedAdsets.length !== 0) {
        setCheckedCompanies([]);

        const findAds = [];
        checkedAdsets.forEach((it) => {
          ads.forEach((el) => {
            if (el['Ad set ID'] === it.item[1]) {
              findAds.push(el);
            }
          });
        });
        setAdsSearch([...findAds]);
        setAdsetsSearch([...adsets]);
      } else {
        setCheckedCompanies([]);
        setCompaniesSearch([...companies]);
        setCheckedAdsets([]);
        setAdsetsSearch([...adsets]);
        setCheckedAds([]);
        setAdsSearch([...ads]);
      }
    }
    if (id === 2) {
      if (checkedCompanies.length !== 0) {
        setCheckedAdsets([]);
        const findAdsets = [];
        checkedCompanies.forEach((it) => {
          [...adsets].forEach((el) => {
            if (el['Campaign ID'] === it.item[1]) {
              findAdsets.push(el);
            }
          });
        });
        const findAds = [];
        checkedCompanies.forEach((it) => {
          ads.forEach((el) => {
            if (el['Campaign ID'] === it.item[1]) {
              findAds.push(el);
            }
          });
        });
        setAdsetsSearch([...findAdsets]);
        setAdsSearch([...findAds]);
      } else {
        setCheckedAdsets([]);
        setAdsetsSearch([...adsets]);
        setCheckedAds([]);
        setAdsSearch([...ads]);
      }
    }
  };

  const checkElement = (item, checkedEls, id) => {
    setSortCompanies(null);
    setSortAdsets(null);
    setSortAds(null);

    setPreSortCompanies(null);
    setPreSortAdsets(null);
    setPreSortAds(null);

    const newCheckedElements = [...checkedEls];

    const findIndex = newCheckedElements.findIndex((el) => el.item[1] === item[1]);
    if (findIndex === -1) {
      newCheckedElements.push({item});
    } else {
      newCheckedElements.splice(findIndex, 1);
    }

    if (id === 1) {
      setCheckedCompanies(newCheckedElements);
      if (newCheckedElements.length !== 0) {
        const findAdsets = [];

        newCheckedElements.forEach((it) => {
          [...adsets].forEach((el) => {
            if (el['Campaign ID'] === it.item[1]) {
              findAdsets.push(el);
            }
          });
        });
        const findAds = [];
        newCheckedElements.forEach((it) => {
          [...ads].forEach((el) => {
            if (el['Campaign ID'] === it.item[1]) {
              findAds.push(el);
            }
          });
        });

        setAdsetsSearch([...findAdsets]);
        setAdsSearch([...findAds]);
      } else {
        if (checkedAdsets.length !== 0) {
          const findAds = [];
          checkedAdsets.forEach((it) => {
            [...ads].forEach((el) => {
              if (el['Ad set ID'] === it.item[1]) {
                findAds.push(el);
              }
            });
          });
          setAdsSearch([...findAds]);
          setAdsetsSearch([...adsets]);
        } else {
          setAdsetsSearch([...adsets]);
          setAdsSearch([...ads]);
        }
      }
    } else if (id === 2) {
      setCheckedAdsets(newCheckedElements);

      if (newCheckedElements.length !== 0) {
        const findAds = [];
        newCheckedElements.forEach((it) => {
          [...ads].forEach((el) => {
            if (el['Ad set ID'] === it.item[1]) {
              findAds.push(el);
            }
          });
        });
        setAdsSearch([...findAds]);
      } else {
        if (checkedCompanies.length !== 0) {
          const findAds = [];
          checkedCompanies.forEach((it) => {
            [...ads].forEach((el) => {
              if (el['Campaign ID'] === it.item[1]) {
                findAds.push(el);
              }
            });
          });
          setAdsSearch([...findAds]);
        } else {
          setAdsSearch([...ads]);
        }
      }
    } else {
      setCheckedAds(newCheckedElements);
    }
  };

  const parseValue = (val) => {
    if (val === '-' || val === '') return 0;
    const numericValue = val.replace(/[^\d.]/g, '');
    return parseFloat(numericValue) || 0;
  };

  const sortArray = (arr, column, type) => {
    if (type === 'up') {
      const newArr = [...arr].sort((a, b) => parseValue(b[column]) - parseValue(a[column]));
      return newArr;
    }
    if (type === 'down') {
      const newArr = [...arr].sort((a, b) => parseValue(a[column]) - parseValue(b[column]));
      return newArr;
    }
  };

  const handleSort = (id, column) => {
    if (id === 1) {
      if (preSortCompanies === null) {
        setPreSortCompanies([...companiesSearch]);
      }

      if (sortCompanies === null) {
        setSortCompanies(`${column}-up`);
        const arr = sortArray(companiesSearch, column, 'up');
        setCompaniesSearch([...arr]);
      } else if (sortCompanies === `${column}-up`) {
        setSortCompanies(`${column}-down`);
        const arr = sortArray(companiesSearch, column, 'down');
        setCompaniesSearch([...arr]);
      } else if (sortCompanies === `${column}-down`) {
        setSortCompanies(null);
        setCompaniesSearch([...preSortCompanies]);
        setPreSortCompanies(null);
      } else {
        setSortCompanies(`${column}-up`);
        const arr = sortArray(companiesSearch, column, 'up');
        setCompaniesSearch([...arr]);
      }
    }
    if (id === 2) {
      if (preSortAdsets === null) {
        setPreSortAdsets([...adsetsSearch]);
      }

      if (sortAdsets === null) {
        setSortAdsets(`${column}-up`);
        const arr = sortArray(adsetsSearch, column, 'up');
        setAdsetsSearch([...arr]);
      } else if (sortAdsets === `${column}-up`) {
        setSortAdsets(`${column}-down`);
        const arr = sortArray(adsetsSearch, column, 'down');
        setAdsetsSearch([...arr]);
      } else if (sortAdsets === `${column}-down`) {
        setSortAdsets(null);
        setAdsetsSearch([...preSortAdsets]);
        setPreSortAdsets(null);
      } else {
        setSortAdsets(`${column}-up`);
        const arr = sortArray(adsetsSearch, column, 'up');
        setAdsetsSearch([...arr]);
      }
    }

    if (id === 3) {
      if (preSortAds === null) {
        setPreSortAds([...adsSearch]);
      }

      if (sortAds === null) {
        setSortAds(`${column}-up`);
        const arr = sortArray(adsSearch, column, 'up');
        setAdsSearch([...arr]);
      } else if (sortAds === `${column}-up`) {
        setSortAds(`${column}-down`);
        const arr = sortArray(adsSearch, column, 'down');
        setAdsSearch([...arr]);
      } else if (sortAds === `${column}-down`) {
        setSortAds(null);
        setAdsSearch([...preSortAds]);
        setPreSortAds(null);
      } else {
        setSortAds(`${column}-up`);
        const arr = sortArray(adsSearch, column, 'up');
        setAdsSearch([...arr]);
      }
    }
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  return (
    <div className='col-xl-12 mb-5 mb-xl-10'>
      <div
        className={css.container}
        style={{
          '--bg': themeMode === 'dark' ? '#15171C' : 'rgba(255, 255, 255, 1)',
          '--tabActive': themeMode === 'dark' ? '#15171C' : 'rgba(255, 255, 255, 1)',
          '--tab': themeMode === 'dark' ? '#15171C' : 'rgba(245, 246, 247, 1)',
          '--svgFillActive': themeMode === 'dark' ? '#1B84FF' : '#1B84FF',
          '--svgFill': themeMode === 'dark' ? 'rgba(255, 255, 255, 1)' : '#292D32',
          '--text': themeMode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(7, 20, 55, 1)',
          '--text2': themeMode === 'dark' ? 'rgba(113, 113, 121, 1)' : 'rgba(120, 130, 157, 1)',
          '--textActive': themeMode === 'dark' ? '#1B84FF' : '#1B84FF',
          '--bgButtons': themeMode === 'dark' ? 'rgba(15, 16, 21, 1)' : 'rgba(245, 246, 247, 1)',
          '--borderTable': themeMode === 'dark' ? 'rgba(30, 31, 36, 1)' : 'rgba(211, 211, 211, 1)',
          '--bgRowTable': themeMode === 'dark' ? 'rgba(27, 29, 35, 1)' : 'rgba(245, 246, 247, 1)',
          '--bgRowTableHover':
            themeMode === 'dark' ? 'rgba(38, 41, 48, 1)' : 'rgba(229, 229, 229, 1)',
          '--bgRowTableActive':
            themeMode === 'dark' ? 'rgba(44, 49, 73, 1)' : 'rgba(212, 230, 253, 1)',
        }}
      >
        <div className={css.tableContainer}>
          <Tabs
            setCurrentShowTable={setCurrentShowTable}
            currentShowTable={currentShowTable}
            checkedCompanies={checkedCompanies}
            checkedAdsets={checkedAdsets}
            cleanCheckedElements={cleanCheckedElements}
          />
          <div style={{position: 'relative'}}>
            <Actions
              setCompaniesQ={setCompaniesQ}
              setAdsetsQ={setAdsetsQ}
              setAdsQ={setAdsQ}
              companiesQ={companiesQ}
              adsetsQ={adsetsQ}
              adsQ={adsQ}
              companies={companies}
              adsets={adsets}
              ads={ads}
              setAdsSearch={setAdsSearch}
              setAdsetsSearch={setAdsetsSearch}
              setCompaniesSearch={setCompaniesSearch}
              currentShowTable={currentShowTable}
              exportCompanies={exportCompanies}
              exportAdsets={exportAdsets}
              exportAds={exportAds}
              date={date}
            />
            <Table
              titles={titlesCompanies}
              obj={companiesSearch}
              q={companiesQ}
              current={currentShowTable}
              setCurrentShowTable={setCurrentShowTable}
              id={1}
              checkedEls={checkedCompanies}
              setCheckedEls={setCheckedCompanies}
              checkElement={checkElement}
              sort={sortCompanies}
              handleSort={handleSort}
              blockerSum={[0, 1]}
              exports={setExportCompanies}
            />
            <Table
              titles={titlesAdsets}
              obj={adsetsSearch}
              q={adsetsQ}
              current={currentShowTable}
              setCurrentShowTable={setCurrentShowTable}
              id={2}
              checkedEls={checkedAdsets}
              setCheckedEls={setCheckedAdsets}
              checkElement={checkElement}
              sort={sortAdsets}
              handleSort={handleSort}
              blockerSum={[0, 1, 2, 3]}
              exports={setExportAdsets}
            />
            <Table
              titles={titlesAds}
              obj={adsSearch}
              q={adsQ}
              current={currentShowTable}
              id={3}
              checkedEls={checkedAds}
              checkElement={checkElement}
              sort={sortAds}
              handleSort={handleSort}
              blockerSum={[0, 1, 2, 3, 4, 5]}
              exports={setExportAds}
            />
            {preloader && <Preloader />}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Treker;
