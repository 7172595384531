/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import css from '../../../../../../../styles/Treker.module.scss';
import {CreateAppModal} from '../../../../../../../_metronic/partials';
import classNames from 'classnames';
import {useThemeMode} from '../../../../../../../_metronic/partials';
import Kanban from './Kanban';

const ColumnsSettings = ({
  setCompaniesQ,
  setAdsetsQ,
  setAdsQ,
  companiesQ,
  adsetsQ,
  adsQ,
  companies,
  adsets,
  ads,
}) => {
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [tab, setTab] = useState(1);

  const [controlledBoard, setBoard] = useState({columns: [{id: 1, cards: []}]});

  const [arrCompanies, setArrCompanies] = useState([]);
  const [arrAdsets, setArrAdsets] = useState([]);
  const [arrAds, setArrAds] = useState([]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    if (
      !!companies?.length &&
      !!adsets?.length &&
      !!ads?.length &&
      !!companiesQ?.length &&
      !!adsetsQ?.length &&
      !!adsQ?.length
    ) {

      const c = Object.keys(companies[0]).map((el) => el);
      const aSets = Object.keys(adsets[0]).map((el) => el);
      const a = Object.keys(ads[0]).map((el) => el);

      const cQ = buildKanban(c, companiesQ);
      const aSetsQ = buildKanban(aSets, adsetsQ);
      const aQ = buildKanban(a, adsQ);

      setArrCompanies(cQ);
      setArrAdsets(aSetsQ);
      setArrAds(aQ);
    }
  }, [companies, companiesQ, adsets, adsetsQ, ads, adsQ]);

  const buildKanban = (arr, q) => {
    const Q = [];
    q.forEach((el) => {
      let find;
      arr.forEach((item, index) => {
        if (index === Number(el)) {
          find = {
            title: item,
            id: Number(el),
            show: typeof el === 'string' ? false : true,
            active: el === 0 ? false : true,
          };
        }
      });
      Q.push(find);
    });
    return Q;
  };

  useEffect(() => {
    if (tab === 1) {
      setBoard({columns: [{id: 1, cards: arrCompanies.filter((item) => !!item.active)}]});
    } else if (tab === 2) {
      setBoard({columns: [{id: 1, cards: arrAdsets.filter((item) => !!item.active)}]});
    } else {
      setBoard({columns: [{id: 1, cards: arrAds.filter((item) => !!item.active)}]});
    }
  }, [tab, arrCompanies, arrAdsets, arrAds]);

  const changeQ = (card, from, to) => {
    const config = localStorage.getItem('appTrekerConfig');
    const configResult = JSON.parse(config) || null;

    if (tab === 1) {
      const newCompaniesQ = [...companiesQ];
      const element = card.id; // Отримуємо елемент з початкової позиції
      newCompaniesQ.splice(from.fromPosition + 1, 1); // Видаляємо елемент з початкової позиції
      newCompaniesQ.splice(to.toPosition + 1, 0, element); // Вставляємо елемент на нову позицію
      setCompaniesQ(newCompaniesQ);

      localStorage.setItem(
        'appTrekerConfig',
        JSON.stringify({...configResult, companies: newCompaniesQ})
      );
    } else if (tab === 2) {
      const newAdsetsQ = [...adsetsQ];
      const element = card.id; // Отримуємо елемент з початкової позиції
      newAdsetsQ.splice(from.fromPosition + 1, 1); // Видаляємо елемент з початкової позиції
      newAdsetsQ.splice(to.toPosition + 1, 0, element); // Вставляємо елемент на нову позицію
      setAdsetsQ(newAdsetsQ);

      localStorage.setItem(
        'appTrekerConfig',
        JSON.stringify({...configResult, adsets: newAdsetsQ})
      );
    } else {
      const newAdsQ = [...adsQ];
      const element = card.id; // Отримуємо елемент з початкової позиції
      newAdsQ.splice(from.fromPosition + 1, 1); // Видаляємо елемент з початкової позиції
      newAdsQ.splice(to.toPosition + 1, 0, element); // Вставляємо елемент на нову позицію
      setAdsQ(newAdsQ);

      localStorage.setItem('appTrekerConfig', JSON.stringify({...configResult, ads: newAdsQ}));
    }
  };

  const changeActive = (id, show) => {
    const config = localStorage.getItem('appTrekerConfig');
    const configResult = JSON.parse(config) || null;

    if (tab === 1) {
      const newCompaniesQ = [...companiesQ];
      const findIndex = newCompaniesQ.findIndex((el) => Number(el) === Number(id));
      if (show) {
        newCompaniesQ[findIndex] = String(newCompaniesQ[findIndex]);
      } else {
        newCompaniesQ[findIndex] = Number(newCompaniesQ[findIndex]);
      }
      setCompaniesQ(newCompaniesQ);

      localStorage.setItem(
        'appTrekerConfig',
        JSON.stringify({...configResult, companies: newCompaniesQ})
      );
    } else if (tab === 2) {
      const newAdsetsQ = [...adsetsQ];
      const findIndex = newAdsetsQ.findIndex((el) => Number(el) === Number(id));
      if (show) {
        newAdsetsQ[findIndex] = String(newAdsetsQ[findIndex]);
      } else {
        newAdsetsQ[findIndex] = Number(newAdsetsQ[findIndex]);
      }
      setAdsetsQ(newAdsetsQ);

      localStorage.setItem(
        'appTrekerConfig',
        JSON.stringify({...configResult, adsets: newAdsetsQ})
      );
    } else {
      const newAdsQ = [...adsQ];
      const findIndex = newAdsQ.findIndex((el) => Number(el) === Number(id));
      if (show) {
        newAdsQ[findIndex] = String(newAdsQ[findIndex]);
      } else {
        newAdsQ[findIndex] = Number(newAdsQ[findIndex]);
      }
      setAdsQ(newAdsQ);

      localStorage.setItem('appTrekerConfig', JSON.stringify({...configResult, ads: newAdsQ}));
    }
  };

  return (
    <>
      <button className={css.button} onClick={() => setShowCreateAppModal(true)}>
        <svg
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            opacity='0.4'
            d='M10.125 3.075V14.925C10.125 16.05 10.605 16.5 11.7975 16.5H14.8275C16.02 16.5 16.5 16.05 16.5 14.925V3.075C16.5 1.95 16.02 1.5 14.8275 1.5H11.7975C10.605 1.5 10.125 1.95 10.125 3.075Z'
          />
          <path d='M1.5 3.075V14.925C1.5 16.05 1.98 16.5 3.1725 16.5H6.2025C7.395 16.5 7.875 16.05 7.875 14.925V3.075C7.875 1.95 7.395 1.5 6.2025 1.5H3.1725C1.98 1.5 1.5 1.95 1.5 3.075Z' />
        </svg>

        <span>Колонки</span>
      </button>
      <CreateAppModal
        customWidth='mw-650px'
        padding='scroll-y mx-0 mx-xl-0 ps-0 pe-0 pt-5 pb-15'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={true}
        title={"Настройка таблиц"}
      >
        <div
          className={css.kanbanContainer}
          style={{
            '--bg': themeMode === 'dark' ? '#15171C' : 'rgba(255, 255, 255, 1)',
            '--tabActive': themeMode === 'dark' ? '#15171C' : 'rgba(255, 255, 255, 1)',
            '--tab': themeMode === 'dark' ? '#15171C' : 'rgba(245, 246, 247, 1)',
            '--svgFillActive': themeMode === 'dark' ? '#1B84FF' : '#1B84FF',
            '--svgFill': themeMode === 'dark' ? 'rgba(255, 255, 255, 1)' : '#292D32',
            '--text': themeMode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(7, 20, 55, 1)',
            '--text2': themeMode === 'dark' ? 'rgba(113, 113, 121, 1)' : 'rgba(120, 130, 157, 1)',
            '--textActive': themeMode === 'dark' ? '#1B84FF' : '#1B84FF',
            '--bgButtons': themeMode === 'dark' ? 'rgba(15, 16, 21, 1)' : 'rgba(245, 246, 247, 1)',
            '--borderTable':
              themeMode === 'dark' ? 'rgba(30, 31, 36, 1)' : 'rgba(211, 211, 211, 1)',
            '--bgRowTable': themeMode === 'dark' ? 'rgba(27, 29, 35, 1)' : 'rgba(245, 246, 247, 1)',
            '--bgRowTableHover':
              themeMode === 'dark' ? 'rgba(38, 41, 48, 1)' : 'rgba(229, 229, 229, 1)',
            '--bgRowTableActive':
              themeMode === 'dark' ? 'rgba(44, 49, 73, 1)' : 'rgba(212, 230, 253, 1)',
          }}
        >
          <section className={css.menu}>
            <div
              className={classNames(css.tab, tab === 1 && css._active)}
              onClick={() => {
                setTab(1);
              }}
            >
              <div className={css.name}>
                <svg
                  className={classNames(css.svg, tab === 1 && css._active)}
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M16.5 8.3025V12.4875C16.5 14.7 14.7 16.5 12.4875 16.5H5.5125C3.3 16.5 1.5 14.7 1.5 12.4875V7.08H16.305C16.4175 7.4175 16.4775 7.7625 16.4925 8.13C16.5 8.1825 16.5 8.25 16.5 8.3025Z' />
                  <path
                    opacity='0.4'
                    d='M16.305 7.08H1.5V4.815C1.5 2.985 2.985 1.5 4.815 1.5H6.5625C7.785 1.5 8.1675 1.8975 8.655 2.55L9.705 3.945C9.9375 4.2525 9.9675 4.2975 10.4025 4.2975H12.495C14.2725 4.29 15.7875 5.46 16.305 7.08Z'
                  />
                </svg>

                <span>Campaigns</span>
              </div>
            </div>
            <div
              className={classNames(css.tab, tab === 2 && css._active)}
              onClick={() => {
                setTab(2);
              }}
            >
              <div className={css.name}>
                <svg
                  className={classNames(css.svg, tab === 2 && css._active)}
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    opacity='0.4'
                    d='M16.5 6.39V2.985C16.5 1.9275 16.02 1.5 14.8275 1.5H11.7975C10.605 1.5 10.125 1.9275 10.125 2.985V6.3825C10.125 7.4475 10.605 7.8675 11.7975 7.8675H14.8275C16.02 7.875 16.5 7.4475 16.5 6.39Z'
                  />
                  <path d='M16.5 14.8275V11.7975C16.5 10.605 16.02 10.125 14.8275 10.125H11.7975C10.605 10.125 10.125 10.605 10.125 11.7975V14.8275C10.125 16.02 10.605 16.5 11.7975 16.5H14.8275C16.02 16.5 16.5 16.02 16.5 14.8275Z' />
                  <path d='M7.875 6.39V2.985C7.875 1.9275 7.395 1.5 6.2025 1.5H3.1725C1.98 1.5 1.5 1.9275 1.5 2.985V6.3825C1.5 7.4475 1.98 7.8675 3.1725 7.8675H6.2025C7.395 7.875 7.875 7.4475 7.875 6.39Z' />
                  <path
                    opacity='0.4'
                    d='M7.875 14.8275V11.7975C7.875 10.605 7.395 10.125 6.2025 10.125H3.1725C1.98 10.125 1.5 10.605 1.5 11.7975V14.8275C1.5 16.02 1.98 16.5 3.1725 16.5H6.2025C7.395 16.5 7.875 16.02 7.875 14.8275Z'
                  />
                </svg>

                <span>Ad sets</span>
              </div>
            </div>
            <div
              className={classNames(css.tab, tab === 3 && css._active)}
              onClick={() => {
                setTab(3);
              }}
            >
              <div className={css.name}>
                <svg
                  className={classNames(css.svg, tab === 3 && css._active)}
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M14.25 6C15.4926 6 16.5 4.99264 16.5 3.75C16.5 2.50736 15.4926 1.5 14.25 1.5C13.0074 1.5 12 2.50736 12 3.75C12 4.99264 13.0074 6 14.25 6Z' />
                  <path
                    opacity='0.4'
                    d='M14.25 7.125C12.39 7.125 10.875 5.61 10.875 3.75C10.875 3.21 11.0175 2.7075 11.2425 2.25H5.64C3.0525 2.25 1.5 3.795 1.5 6.39V12.3525C1.5 14.955 3.0525 16.5 5.64 16.5H11.6025C14.1975 16.5 15.7425 14.955 15.7425 12.36V6.7575C15.2925 6.9825 14.79 7.125 14.25 7.125Z'
                  />
                  <path d='M8.8125 10.5H5.0625C4.755 10.5 4.5 10.245 4.5 9.9375C4.5 9.63 4.755 9.375 5.0625 9.375H8.8125C9.12 9.375 9.375 9.63 9.375 9.9375C9.375 10.245 9.12 10.5 8.8125 10.5Z' />
                  <path d='M11.8125 13.5H5.0625C4.755 13.5 4.5 13.245 4.5 12.9375C4.5 12.63 4.755 12.375 5.0625 12.375H11.8125C12.12 12.375 12.375 12.63 12.375 12.9375C12.375 13.245 12.12 13.5 11.8125 13.5Z' />
                </svg>

                <span>Ads</span>
              </div>
            </div>
          </section>
          <section className={css.kanban}>
            <div className={css.title}>Доступные колонки</div>
            <Kanban
              controlledBoard={controlledBoard}
              setBoard={setBoard}
              changeQ={changeQ}
              changeActive={changeActive}
              tab={tab}
            />
          </section>
        </div>
      </CreateAppModal>
    </>
  );
};

export default ColumnsSettings;
