/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import {useLocation, useParams} from 'react-router-dom';
import TeamHeader from '../../../team/RD/TeamHeader';
import Chart from '../../../../../widgets/Chart';
import Chart3 from '../../../../../widgets/Chart3';
import PageWrapper from '../../../../../modules/PageWrapper';
import {findKPIRd} from '../../../../../functions/findKPI';
import Leads from './Leads';
import Traffic from './Traffic';
import Stages from './Stages';

const Component = observer(({timeConfig, isVerify, setPreloader, page, setPage, id}) => {
  const store = useStore();

  const [managerStats, setManagerStats] = useState({});
  const [depositsSchedule, setDepositsSchedule] = useState({});

  useEffect(() => {
    isVerify && getData();
  }, [timeConfig, isVerify]);

  const getData = async () => {
    const tc = timeConfig;
    const getManagerStats = await store.salesManagerRdMainStats(id, tc);
    const rdList = await store.salesGetKpiRdList();
    const getDepositsSchedule = await store.salesManagerRdDepositsSchedule(id);

    setManagerStats({
      ...getManagerStats,
      indicator: findKPIRd(
        rdList,
        getManagerStats?.count_qualifications,
        getManagerStats?.activity,
        getManagerStats?.rd,
        'manager_kpi'
      ),
    });
    setDepositsSchedule({graph: getDepositsSchedule});
    setPreloader(false);
  };

  return (
    <>
      <TeamHeader
        page={page}
        setPage={setPage}
        data={managerStats}
        isManager={true}
        links={[
          {link: 'statistics', title: 'Статистика'},
          {link: 'leads', title: 'Лиды'},
          {link: 'traffic', title: 'Трафик'},
          {link: 'stages', title: 'Этапы'},
        ]}
      />
      {page === 'statistics' && (
        <>
          <div className='row gx-5 gx-xl-10'>
            <div className='col-xl-12 mb-5 mb-xl-10'>
              <Chart data={depositsSchedule} />
            </div>
            {/* <div className='col-xl-5 mb-5 mb-xl-10'>
              <Chart3
              countries={geoQualifications || []}
              />
            </div> */}
          </div>
        </>
      )}
      {page === 'leads' && <Leads timeConfig={timeConfig} id={id} isVerify={isVerify} />}
      {page === 'traffic' && <Traffic id={id} />}
      {page === 'stages' && <Stages id={id} workspace_id={managerStats.workspace_id} />}
    </>
  );
});

const ManagerRD = ({timeConfig, timeStart, timeEnd}) => {
  const [page, setPage] = useState('statistics');
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    setBreadcrumbs({
      br: [
        {title: 'PanelHunt', path: `/dashboard/main`},
        {isSeparator: true},
        {
          title: `Менеджера RD`,
          path: `/sales/rd/managers`,
          isActive: true,
        },
        {isSeparator: true},
        {
          title: `Менеджер RD #${params.id}`,
          path: `/sales/rd/managers/${params.id}`,
          isActive: true,
        },
      ],
      title: `Менеджер RD #${params.id}`,
    });
  }, [page]);

  return (
    <>
      <PageWrapper
        breadcrumbs={breadcrumbs.br}
        title={breadcrumbs.title}
        firstTimeConfig='week_salles'
      >
        <Component page={page} setPage={setPage} id={params.id} />
      </PageWrapper>
    </>
  );
};

export default observer(ManagerRD);
