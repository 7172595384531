/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import css from '../../../../../../../styles/Treker.module.scss';
import classNames from 'classnames';
import {
  formatNumber,
  formatNumber2,
  formatNumberToCash,
} from '../../../../../../functions/formatNumber';

const Table = ({
  titles,
  obj,
  q,
  id,
  current,
  setCurrentShowTable,
  checkedEls,
  setCheckedEls,
  checkElement,
  handleSort,
  sort,
  blockerSum,
  exports,
}) => {
  const [tableRows, setTableRows] = useState([]);
  const [tableSum, setTableSum] = useState([]);

  useEffect(() => {
    if (!obj.length) {
      setTableRows([]);
      setTableSum([]);
      return;
    }

    const rows = obj.map((item) => {
      const parse = Object.values(item);
      return parse;
    });

    setTableRows(rows);

    const sum = [];
    const sings = [];

    rows.forEach((item, index) => {
      item.forEach((el, i) => {
        if (sings[i] !== '%' && sings[i] !== '$') {
          sings[i] = String(el).replace(/[0-9.,]/g, '');
        }

        if (el !== '-') {
          sum[i] = Number(sum[i] || 0) + Number(String(el).replace(/[^\d.]/g, ''));
        } else {
          sum[i] = Number(sum[i] || 0) + 0;
        }

        if (blockerSum.includes(i)) {
          sum[i] = '';
          sings[i] = '';
        }
      });
    });

    const sumResult = sum.map((item, index) => {
      if (sings[index] !== '%' && sings[index] !== '$') {
        return item !== 0 ? formatNumber(item) : '-';
      }
      if (sings[index] === '%') {
        return item !== 0 ? `${formatNumber2(item / obj.length)}%` : '-';
      }
      if (sings[index] === '$') {
        return item !== 0 ? `${formatNumberToCash(item)}` : '-';
      }
    });

    setTableSum(sumResult);
    createDataForExel(titles, rows, sumResult, q);
  }, [obj]);

  const createDataForExel = (titles, rows, sum, q) => {
    const newTitles = [];

    q.forEach((cell) => {
      if (typeof cell !== 'string') {
        newTitles.push(titles[cell]);
      }
    });

    const resRows = rows.map((item, index) => {
      const newItem = {};
      item.forEach((el, i) => {
        if (
          titles[i] === 'Ad' ||
          titles[i] === 'Ad ID' ||
          titles[i] === 'Ad set' ||
          titles[i] === 'Ad set ID' ||
          titles[i] === 'Campaign' ||
          titles[i] === 'Campaign ID'
        ) {
          newItem[titles[i]] = el;
        } else {
          if (
            titles[i] === 'Click2Lead' ||
            titles[i] === 'Click2Chat' ||
            titles[i] === 'Click2Registration' ||
            titles[i] === 'Click2FirstDeposit' ||
            titles[i] === 'Click2Cpa' ||
            titles[i] === 'Lead2Chat' ||
            titles[i] === 'Lead2Registration' ||
            titles[i] === 'Lead2FirstDeposit' ||
            titles[i] === 'Lead2Cpa' ||
            titles[i] === 'Registration2FirstDeposit' ||
            titles[i] === 'Registration2Cpa' ||
            titles[i] === 'FirstDeposit2Cpa'
          ) {
            newItem[titles[i]] = el === '-' ? 0 : Number(String(el).replace(/[^\d.]/g, '')) / 100;
          } else {
            newItem[titles[i]] = el === '-' ? 0 : Number(String(el).replace(/[^\d.]/g, ''));
          }
        }
      });
      return newItem;
    });

    const newSum = {};
    sum.forEach((item, index) => {
      if (
        titles[index] !== 'Ad' &&
        titles[index] !== 'Ad ID' &&
        titles[index] !== 'Ad set' &&
        titles[index] !== 'Ad set ID' &&
        titles[index] !== 'Campaign' &&
        titles[index] !== 'Campaign ID'
      ) {
        if (
          titles[index] === 'Click2Lead' ||
          titles[index] === 'Click2Chat' ||
          titles[index] === 'Click2Registration' ||
          titles[index] === 'Click2FirstDeposit' ||
          titles[index] === 'Click2Cpa' ||
          titles[index] === 'Lead2Chat' ||
          titles[index] === 'Lead2Registration' ||
          titles[index] === 'Lead2FirstDeposit' ||
          titles[index] === 'Lead2Cpa' ||
          titles[index] === 'Registration2FirstDeposit' ||
          titles[index] === 'Registration2Cpa' ||
          titles[index] === 'FirstDeposit2Cpa'
        ) {
          newSum[titles[index]] =
            item === '-' ? 0 : Number(String(item).replace(/[^\d.]/g, '')) / 100;
        } else {
          newSum[titles[index]] = item === '-' ? 0 : Number(String(item).replace(/[^\d.]/g, ''));
        }
      }
    });
    resRows.push(newSum);
    exports({rows: resRows, titles: newTitles});
  };

  return (
    <div
      className={css.table}
      style={{
        position: id === current ? 'relative' : 'absolute',
        visibility: id === current ? 'visible' : 'hidden',
        display: id === current ? 'block' : 'none',
        zIndex: id === current ? '1' : '0',
      }}
    >
      <table className={css.field}>
        <thead className={classNames(css.row, css.titles)}>
          <tr>
            <th className={classNames(css.cell, css.title, css.checkboxContainer)}></th>
            {q.map((cell, index) => {
              return (
                <React.Fragment key={`${cell}-${index}-head`}>
                  {typeof cell === 'string' ? (
                    <></>
                  ) : (
                    <th className={classNames(css.cell, css.title, index === 0 && css._sticky)}>
                      {titles[cell] !== 'Ad' &&
                      titles[cell] !== 'Ad ID' &&
                      titles[cell] !== 'Ad set' &&
                      titles[cell] !== 'Ad set ID' &&
                      titles[cell] !== 'Campaign ID' &&
                      titles[cell] !== 'Campaign' ? (
                        <span
                          onClick={() => {
                            handleSort(id, titles[cell]);
                          }}
                        >
                          <span>{titles[cell]}</span>
                          <svg
                            className={classNames(
                              css.svg,
                              titles[cell] + '-up' === sort && css._up,
                              titles[cell] + '-down' === sort && css._down
                            )}
                            width='12'
                            height='7'
                            viewBox='0 0 12 7'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M11 6L6 1L1 6'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      ) : (
                        <span style={{cursor: 'default'}}>{titles[cell]}</span>
                      )}
                    </th>
                  )}
                </React.Fragment>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableRows.map((item, index) => {
            const getChecked = () => {
              const checked = checkedEls.findIndex((el) => el.item[1] === item[1]);
              if (checked !== -1) {
                return true;
              }
              return false;
            };

            return (
              <tr
                className={classNames(css.row, !!getChecked() && css._active)}
                key={`${index}-row-${id}`}
              >
                <th className={classNames(css.cell, css.title, css.checkboxContainer)}>
                  {id !== 3 && (
                    <input
                      type='checkbox'
                      name=''
                      id=''
                      checked={getChecked()}
                      onChange={() => {
                        checkElement(item, checkedEls, id);
                      }}
                    />
                  )}
                </th>
                {q.map((cell, elIndex) => {
                  return (
                    <React.Fragment key={`${index}-row-${id}-${elIndex}`}>
                      {typeof cell === 'string' ? (
                        <></>
                      ) : (
                        <th
                          className={classNames(
                            css.cell,
                            css._body,
                            elIndex === 0 && css._active,
                            elIndex === 0 && css._sticky
                          )}
                          onClick={() => {
                            if (
                              elIndex === 0 &&
                              // checkedEls.length === 0 &&
                              (id === 1 || id === 2)
                            ) {
                              setCurrentShowTable(id + 1);
                              setCheckedEls([]);
                              checkElement(item, [], id);
                            }
                          }}
                        >
                          {item[cell]}
                        </th>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            );
          })}
          <tr className={classNames(css.row, css.bottom)}>
            <th className={classNames(css.cell, css._body, css.checkboxContainer)}></th>
            {q?.map((cell, index) => {
              return (
                <React.Fragment key={`item-${tableRows.length + index}-${index}-bottom`}>
                  {typeof cell === 'string' ? (
                    <></>
                  ) : (
                    <>
                      {cell === 0 && (
                        <th className={classNames(css.cell, css._body, css._sticky)}>
                          Всего ({tableRows?.length})
                        </th>
                      )}
                      {cell !== 0 && (
                        <th
                          className={classNames(css.cell, css._body)}
                          style={{color: 'var(--text)'}}
                        >
                          {tableSum[cell] === '0' ? '' : tableSum[cell]}
                        </th>
                      )}
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
