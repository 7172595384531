import React, {useEffect, useState} from 'react';
import css from '../../../../../../../styles/Treker.module.scss';
import classNames from 'classnames';
import {useThemeMode} from '../../../../../../../_metronic/partials';
import Export from './Export';
import ColumnsSettings from './ColumnsSettings';
import InputSearch from '../../../../../../shared/InputSearch';

const formats = {
  Click2Lead: '#,##0.00%',
  Click2Chat: '#,##0.00%',
  Click2Registration: '#,##0.00%',
  Click2FirstDeposit: '#,##0.00%',
  Click2Cpa: '#,##0.00%',
  Lead2Chat: '#,##0.00%',
  Lead2Registration: '#,##0.00%',
  Lead2FirstDeposit: '#,##0.00%',
  Lead2Cpa: '#,##0.00%',
  Registration2FirstDeposit: '#,##0.00%',
  Registration2Cpa: '#,##0.00%',
  FirstDeposit2Cpa: '#,##0.00%',
  Доход: '$#,##0.00',
};

const Actions = ({
  setCompaniesQ,
  setAdsetsQ,
  setAdsQ,
  companiesQ,
  adsetsQ,
  adsQ,
  companies,
  adsets,
  ads,
  setAdsSearch,
  setAdsetsSearch,
  setCompaniesSearch,
  currentShowTable,
  exportCompanies,
  exportAdsets,
  exportAds,
  date,
}) => {
  return (
    <div className={css.actions}>
      <div className={css.buttons}>
        <ColumnsSettings
          setCompaniesQ={setCompaniesQ}
          setAdsetsQ={setAdsetsQ}
          setAdsQ={setAdsQ}
          companiesQ={companiesQ}
          adsetsQ={adsetsQ}
          adsQ={adsQ}
          companies={companies}
          adsets={adsets}
          ads={ads}
        />
        {currentShowTable === 1 && (
          <Export
            data={exportCompanies.rows}
            fieldsToKeep={exportCompanies.titles}
            fileName={'campaign'}
            time={date}
            columnFormats={formats}
          />
        )}
        {currentShowTable === 2 && (
          <Export
            data={exportAdsets.rows}
            fieldsToKeep={exportAdsets.titles}
            fileName={'adsets'}
            time={date}
            columnFormats={formats}
          />
        )}
        {currentShowTable === 3 && (
          <Export
            data={exportAds.rows}
            fieldsToKeep={exportAds.titles}
            fileName={'ads'}
            time={date}
            columnFormats={formats}
          />
        )}
      </div>
      {currentShowTable === 1 && (
        <InputSearch
          title={'Поиск...'}
          style={{minWidth: '300px'}}
          array={companies}
          newArray={setCompaniesSearch}
          searchParameters={['Campaign', 'Campaign ID']}
        />
      )}
      {currentShowTable === 2 && (
        <InputSearch
          title={'Поиск...'}
          style={{minWidth: '300px'}}
          array={adsets}
          newArray={setAdsetsSearch}
          searchParameters={['Ad set', 'Ad set ID']}
        />
      )}
      {currentShowTable === 3 && (
        <InputSearch
          title={'Поиск...'}
          style={{minWidth: '300px'}}
          array={ads}
          newArray={setAdsSearch}
          searchParameters={['Ad', 'Ad set']}
        />
      )}
    </div>
  );
};

export default Actions;
